<template>
  <div class="auth-wrapper auth-v2">
        <b-spinner
            variant="primary"
            v-if="isSpinner"
            large
            class="pageLoadSpinners webAppSpinner"
            style="top:22%;margin-left:2%;"
        />
    <b-row class="auth-inner m-0" v-if="!isSpinner">

      <!-- Brand logo-->
      <b-link class="brand-logo">
       
          <!-- <img
            src="@/assets/images/logo/tutorLine.png"
            alt="logo"
          /> -->
           <!-- <img src="@/assets/images/erflog/logo.png" alt=""> -->
           <img src="@/assets/images/tutorimages/Logo.png" alt="">
     
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0 mobile_bg_main"
      >      
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
             src="@/assets/images/tutorimages/BG.png"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login--> 
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >      
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >     
        <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 
          </b-card-title>        

          <!-- form -->
          <validation-observer
            ref="simpleRules"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="setUserPassword"
            >
                      <!-- password -->
              <b-form-group>
                <label label-for="reset-password-new">New Password<span class="redcolorstar">*</span></label>
                <validation-provider #default="{ errors }" name="password " vid="Password" rules="required|password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="reset-password-new" v-model="password" :type="password1FieldType" class="form-control-merge" name="reset-password-new" placeholder="Enter New Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            
              <!-- confirm password -->
              <b-form-group>
                <label label-for="reset-password-confirm">Confirm Password<span class="redcolorstar">*</span></label>
                <validation-provider #default="{ errors }"  name="confirm password" rules="required|confirmed:Password" >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="reset-password-confirm" v-model="cPassword" :type="password2FieldType" class="form-control-merge" name="reset-password-confirm" placeholder="Enter Confirm Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] == 'The confirm password field confirmation does not match' ?'New password and confirm password does not match':errors[0]}}</small>
                </validation-provider>
              </b-form-group> 

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <span v-if="!isLogging">
                  Set New Password
                </span>
                <span v-else>
                  <b-spinner small/>
                </span>
              </b-button>
            </b-form>
          </validation-observer>
          </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, 
  BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'; //FOR NOTIFICATION
import firebase from "@/utils/firebaseInit";
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from '@/utils/firebaseCollection.js';
import moment from 'moment'
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {      
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      isLogging:false,
      userData:[],
      isSpinner:true
    }
  },  
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },  
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
    this.isSpinner = true;
      //GET USER STORE COLLECTION DATA
      getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS,'id','==',this.$route.query.query,(res)=>{
        if(res && res.length > 0){
          this.userData = res
          this.countMinute(this.userData).then((difInMin) => {
            console.log('diff',difInMin)
            if(this.userData[0].webAppRandomeCode != this.$route.query.rCode || difInMin > 5){
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Password reset link is expired.',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                },
              })
              this.$router.replace('/login');
            }else{
              this.isSpinner = false;
            }
          }) 
        }
      })   
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    setUserPassword(){
      this.countMinute(this.userData).then((difInMin) => {
        if(difInMin < 5){
       
          this.isLogging=true
          this.$refs.simpleRules.validate().then(success => {
            if (success) {
              if(this.userData.length > 0){
                    this.$axios.post(process.env.VUE_APP_EMAIL_SENT_API + '/api/v1/resetPassword',{
                        docId:this.userData[0].id,
                        newPassword : this.password
                    }).then((res) => {
                      if(res.data.status){
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Password Update Successfully.',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text:'Now log in with new password.'
                          },
                        })
                        this.$router.replace('/login'); 
                      }else{
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: res.data.statusText,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                          },
                        })
                      }
                    }).catch(error => { 
                      this.isLogging=false
                      console.error(error,"?????")
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Invalid user.',
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                        },
                      })
                      this.$router.replace('/login');
                    }); 
              }           
            }
            else
            {
              this.isLogging=false
            }
          })
        }else{
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Password reset link is expired.',
              icon: 'CoffeeIcon',
              variant: 'danger',
            },
          })
          this.$router.replace('/login');
        }
      })
    },
    countMinute(userData){
      return new Promise((resolve,reject) => {
        try{
          var startTime = moment(new Date(userData[0].webAppRandomeCodeSendTime.seconds*1000), 'HH:mm a');
          var endTime = moment(new Date(), 'HH:mm a');
          var duration = moment.duration(endTime.diff(startTime));
          var difInMin = (duration.hours() * 60) + duration.minutes()
          console.log('difInMin',difInMin)
          resolve(difInMin);
        }catch(error){
          console.log(error);
          reject('error found in calculte minute');
        }    
      })
    }
  },  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
