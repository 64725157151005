<template>
    <div>
        <b-spinner
            variant="primary"
            v-if="isSpinner"
            large
            class="pageLoadSpinners webAppSpinner"
        />
        <img src="@/assets/images/emptyData.png" class="emptyData_img" v-if="Object.keys(bookedObject).length === 0">
        <span v-if="suspendErrorMessage!= ''" style="color:red;">{{suspendErrorMessage}}</span>
        <div v-if="Object.keys(bookedObject).length > 0" class="content_value">
          <label>Booking ID</label>
          <p class="booking_id">{{bookedObject.bookingId}}</p>
          <h3>{{bookedObject.sessionTitle}}</h3>
          <p class="subject">{{bookedObject.category}} <span v-if="bookedObject.subCategory != ''"> > </span> {{bookedObject.subCategory}}</p>
          <p class="bookedUser">Your {{bookedObject.user}} will be</p>
          <div class="d-flex justify-content-center imagewrapper">
            <img :src="bookedObject.image" />
            <div class="image_title">
              <p>{{bookedObject.fullName}}</p>
              <span v-if="bookedObject.tutorType != ''">{{bookedObject.tutorType}}</span>
            </div>
          </div>
          <p class="bookedUser">Your booking slot(s)</p>
          <p class="date_div">{{bookedObject.bookingDate}}</p>
          <p class="booking-time">{{bookedObject.bookingTime}}</p>
          <button class="button" v-if="bookedObject.checkDate" @click="JoinMeeting()" :disabled="isSpinner">Join Session</button>
          <p v-else-if="!bookedObject.checkDate" class="timerArea">
            <span class="startin_text">Start in :</span>
             <span class="timer_session">
            <img src="@/assets/images/icons/ClockIcon.png" alt="logo"/> 
            {{sessionTimer}}</span>
          </p>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex';
import Cookies from 'js-cookie'
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries";
import {
 BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BFormRadio,
  BFormRadioGroup,

} from "bootstrap-vue";
import { dbCollections } from '@/utils/firebaseCollection';
import firebase from "@/utils/firebaseInit"
const db = firebase.firestore();
export default {
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BButton,
        BSpinner,
        BFormCheckbox,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BListGroup,
        BListGroupItem,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BFormRadio,
        BFormRadioGroup,
        ValidationProvider, ValidationObserver,
    },
    data(){
        return{
            bookingId:'',
            bookedObject : {},
            loginUserData:{},
            isSpinner:false,
            bookedIndex:'',
            sessionTimer:'',
            timerClose:false,
            suspendErrorMessage:'',
            meetingPassword:'',
            required,
            sessionDetail:false
        }
    },
    created(){
        this.loginUserData = JSON.parse(localStorage.getItem('currentUser'))
        if(this.loginUserData.status == 2){
            this.suspendErrorMessage = 'User is suspended'
        }
        this.$root.$on('fetchSessionDetail',(val) => {
            // debugger
            if(val){
                this.bookingId = val;
                this.sessionDetail = true;
                this.getSessionDetailFromId(this.bookingId);
                this.myfunc();
            }else{
                this.bookedObject = {}
                setTimeout(() => {
                    this.sessionDetail = false;
                },3000)
            }
        })
            
        this.getBooking();
        this.getCategories();
        this.getSubCategories();
        this.getUsers();
    },
    methods:{
        ...mapActions({
            getBooking : 'booking/getBookingData',
            getCategories:'categoryManagement/getCategoriesData',
            getSubCategories:'categoryManagement/getSubCategoriesData',
            getUsers:'userManagement/getUsersData',
        }),
        // GET DATA OF BOOKING USING BOOKINGID (Shraddha)
        getSessionDetailFromId(bookId){
          // debugger // eslint-disable-line
          var self = this;
          self.isSpinner = true;
          self.bookedObject = {}
          setTimeout(() => {
              // console.log('getBookinglist',self.getBookinglist,self.getUsersList,self.getCategoryList,self.getSubCategoryList);
              var index = self.getBookinglist.findIndex(item => {
                  return item.id == bookId  
              })
              self.bookedIndex = index;
              if(index > -1){
                var learnerindex = self.getUsersList.findIndex(item => {
                    return item.id == self.getBookinglist[index].learnerId
                })
                var tutorindex = self.getUsersList.findIndex(item => {
                    return item.id == self.getBookinglist[index].tutor
                })
                var catindex = self.getCategoryList.findIndex(item => {
                    return item.id == self.getBookinglist[index].category 
                })
                var subcatindex = self.getSubCategoryList.findIndex(item => {
                    return item.id == self.getBookinglist[index].subCategory 
                })
                  try{
                      if(self.getBookinglist[index].learnerId == self.loginUserData.id){
                          self.bookedObject = {
                              bookingId : self.getBookinglist[index].bookingId,
                              id : self.getBookinglist[index].id,
                              sessionTitle: self.getBookinglist[index].sessionDetails.name,
                              category: self.getCategoryList[catindex].categoryName,
                              subCategory: subcatindex > -1  ? self.getSubCategoryList[subcatindex].subCategoryName : '',
                              image: self.getUsersList[tutorindex].profilePicture ? self.getUsersList[tutorindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                              tutorType:self.getUsersList[tutorindex].tutorType,
                              fullName: `${self.getUsersList[tutorindex].firstName} ${self.getUsersList[tutorindex].lastName}`,
                              bookingDate: moment(self.getBookinglist[index].startTime.seconds*1000).format("ddd DD MMM"),
                              bookingTime: moment(self.getBookinglist[index].startTime.seconds*1000).format("hh:mm A")+" to "+
                                      moment(self.getBookinglist[index].endTime.seconds*1000).format("hh:mm A"),
                              user:'tutor',
                              checkDate:self.checkDate(self.getBookinglist[index].startTime,self.getBookinglist[index].endTime),
                              startTime:self.getBookinglist[index].startTime,
                              endTime: self.getBookinglist[index].endTime,
                              totalBookingTimeInMinutes: self.getBookinglist[index].totalBookingTimeInMinutes,
                              meetingPassword:  self.getBookinglist[index].meetingPassword 
                          }
                      }else if(self.getBookinglist[index].tutor == self.loginUserData.id){
                          self.bookedObject = {
                              bookingId : self.getBookinglist[index].bookingId,
                              id : self.getBookinglist[index].id,
                              sessionTitle: self.getBookinglist[index].sessionDetails.name,
                              category: self.getCategoryList[catindex].categoryName,
                              subCategory: subcatindex > -1  ? self.getSubCategoryList[subcatindex].subCategoryName : '',
                              image: self.getUsersList[learnerindex].profilePicture ? self.getUsersList[learnerindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                              tutorType:'',
                              fullName: `${self.getUsersList[learnerindex].firstName} ${self.getUsersList[learnerindex].lastName}`,
                              bookingDate:moment(self.getBookinglist[index].startTime.seconds*1000).format("ddd DD MMM"),
                              bookingTime: moment(self.getBookinglist[index].startTime.seconds*1000).format("hh:mm A")+" to "+
                                      moment(self.getBookinglist[index].endTime.seconds*1000).format("hh:mm A"),
                              user:'learner',
                              checkDate:self.checkDate(self.getBookinglist[index].startTime,self.getBookinglist[index].endTime),
                              startTime:self.getBookinglist[index].startTime,
                              endTime: self.getBookinglist[index].endTime,
                              totalBookingTimeInMinutes: self.getBookinglist[index].totalBookingTimeInMinutes,
                              meetingPassword:  self.getBookinglist[index].meetingPassword               
                          } 
                      }
                    //   console.log('self.bookedObject',self.bookedObject);
                      self.isSpinner = false;
                  }
                  catch(error){
                      console.log(error);
                      self.isSpinner = false;
                  }
              }
          },3000)
        },
        // DATE CHECKING FOR DISPLAY TIMER OR JOIN BUTTON (Shraddha)
        checkDate(date1,date2){
            // debugger
         const beforeFromTime = moment(new Date(date1.seconds*1000)).subtract({'minutes' : 15});
          if(new Date(beforeFromTime) <= new Date() && new Date(date2.seconds*1000) >= new Date()){
            return true;
          }else{
            return false;
          }
        },
        // FOR HANDLING TIMER (Shraddha)
        myfunc(){
            // debugger
          var self = this;
          var t = setInterval(function() {
            // console.log(new Date())
              var countDownDate = self.getBookinglist[self.bookedIndex] ? new Date((self.getBookinglist[self.bookedIndex].startTime.seconds*1000)).getTime() : '';
              var now = new Date().getTime();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
              var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
            if(days > 0){
                hours += (days*24)
            }
              // console.log('hoursminutesseconds;',hours+':'+minutes+':'+seconds);
            if(self.sessionTimer != '00:00:00'){
                self.sessionTimer =  self.addZero(hours)+':'+self.addZero(minutes)+':'+self.addZero(seconds);
            }else{
            //  debugger // eslint-disable-line
                self.bookedObject.checkDate = true;
                clearInterval(t);
                if(!self.timerClose){
                self.timerClose = true;
                self.$root.$emit('startSession',self.getBookinglist[self.bookedIndex].id);
                }
            }
          }, 1000)
        },
        // ADDZERO FUNCTION IS USED FOR ADD 0 BEFORE VALUE IF VALUE IS LESS THEN 10 (Shraddha)
        addZero(val){
          if(val > 9){
            return val;
          }else{
            return '0' + val;
          }
        },
        // CALL WHEN JOIN MEETING BUTTON CLICK (Shraddha)
        JoinMeeting(){
            // debugger // eslint-disable-line
            var self = this;
            self.isSpinner = true;
            self.UpdateEntryInDatbase().then(() => {
                // Cookies.set('id', self.loginUserData.id , { expires: 1 , path: ''})
                // Cookies.set('bookingid', self.bookedObject.id , { expires: 1 , path: ''})
                // Cookies.set('fullName', self.loginUserData.fullName , { expires: 1 , path: ''})
                // Cookies.set('pass', self.bookedObject.meetingPassword , { expires: 1 , path: ''})
                // window.location.replace(`http://localhost:3000/video/${self.loginUserData.id}/${self.bookedObject.id}`)

                Cookies.set('id', self.loginUserData.id , { domain: '.tutorline.academy' })
                Cookies.set('bookingid', self.bookedObject.id , { domain: '.tutorline.academy' })
                Cookies.set('fullName', self.loginUserData.fullName , { domain: '.tutorline.academy' })
                Cookies.set('pass', self.bookedObject.meetingPassword , { domain: '.tutorline.academy' })
                window.location.replace(process.env.VUE_APP_MEETING_LINK+`/video/${self.loginUserData.id}/${self.bookedObject.id}`)
            });  
        },
        // UPDATE ENTRY IN SESSIONHISTORY COLLECTION WHEN USER JOIN MEETING (Shraddha)
        UpdateEntryInDatbase(){
            // debugger // eslint-disable-line
            var self = this;
            return new Promise((resolve,reject) => {
                try{
                    const sessionhistory = db.collection(dbCollections.BOOKING).doc(self.bookedObject.id).collection(dbCollections.SESSIONHISTORY).doc();
                    let addObject = {
                        id:sessionhistory.id,
                        createdAt:new Date(),
                        action:'join',
                        platform:'web',
                        userId:this.loginUserData.id
                    }
                    insertFirebase.setDataToRootSubCollectionWithDocKey(dbCollections.BOOKING,self.bookedObject.id,dbCollections.SESSIONHISTORY,sessionhistory.id,addObject,(res) => {
                        console.log('res',res)
                        const updateStatus = {
                            status:5
                        }
                        updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,self.bookedObject.id,updateStatus,(resData) => {
                            console.log(resData,'resData');
                            resolve('error not found')  
                        })
                    })
                }catch(error){
                    console.log(error,'error');
                    reject('error')
                    self.isSpinner = false;
                }
            })
        }, 
    },
    computed: {
        ...mapGetters({
          getBookinglist : 'booking/getBooking', 
          getUsersList:'userManagement/getUsers',
          getCategoryList:'categoryManagement/getCategories',
          getSubCategoryList:'categoryManagement/getSubCategories',
        }),
    }
}
</script>

<style scoped>
*{
      font-family: 'Rubik';
}
div#app {
    background-color: #F2FAFF;
}
.content_value {
    text-align: center;
    padding: 9% 0px;
      background-color: #f2faff!important;
}
.content.app-content
{
  background-color: #f2faff!important;
}
/* .vertical-layout .content.app-content {
    padding: calc(1.5rem + 4.45rem + 1.3rem) 2rem 0!important;
} */
.content_value label {
    color: #797979;
    font-size: 18px;
    font-family: 'Rubik';
    line-height: 21.33px;
    font-weight: 400;
    margin-bottom: 0px!important;
    display:block;
}
.content_value p.booking_id {
  font-size: 20px;
  color: #222222;
  font-family: 'Rubik';
  line-height: 23.7px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 11px;
}
.content_value h3 {
    color: #222222;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 22px;
    font-family: 'Rubik';
    margin-bottom: 13px;
}
.imagewrapper img {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background-repeat: no-repeat;
    /* object-fit: fill; */
}
.imagewrapper .image_title {
    margin-left: 10px;
    text-align: left;
}
p.subject {
    font-size: 20px;
    line-height: 22px;
    color: #797979;
    text-transform: capitalize;
    font-family: 'Rubik';
    font-weight: 400;
}
p.bookedUser {
    font-size: 18px;
    color: #797979;
    line-height: 21.33px;
    margin-top: 40px;
     font-family: 'Rubik';
    font-weight: 400;
        margin-bottom: 16px;
}
.image_title p {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 22px;
    color: #112556;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.image_title span {
    display: inline-block;
        text-align: center!important;
    background-color: #F8EEFF;
    text-align: center;
    color: #8F00FF;
    border: 1px solid #8F00FF;
    border-radius: 8px;
    margin-top: 4px;
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    padding: 4px 9px;
}
p.date_div {
    color: #222222;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Rubik';
    line-height: 23.7px;
    margin-top: -5px;
    margin-bottom: 10px;
}
p.booking-time {
    font-size: 20px;
    color: #222222;
    font-family: 'Rubik';
    line-height: 23.7px;
       font-weight: 400;
       margin-bottom:40px;
}
button.button {
    background-color: #0096DB;
    color: #fff;
    border: 1px solid #0096DB;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 8px;
    line-height: 21.33px;
}
span.startin_text {
    font-size: 18px;
    color: #222222;
    font-family: 'Rubik';
    font-weight: 500;
    line-height: 22px;
}
span.timer_session {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    font-family: 'Rubik';
    background-color: #FFDDDD;
    padding: 11px 15px;
    border-radius: 8px;
    margin-left: 10px;
}
p.timerArea {
   display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
span.timer_session img {
    position: relative;
    top: -1px;
    left: -3px;
}
.content.app-content {
    background-color: #f2faff!important;
}
.imagewrapper {
    align-items: center;
}
img.emptyData_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}
</style>