<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <span class="brand-logo">
          <img
            src="@/assets/images/logo/tutorLine.png"
            alt="logo"
          />
        </span> -->
         <!-- <img src="@/assets/images/erflog/logo.png" alt=""> -->
         <!-- <img src="@/assets/images/VirtuSomm/VirtuSomm.png" alt=""> -->
         <img src="@/assets/images/tutorimages/Logo.png" alt="">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0 mobile_bg_main"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            src="@/assets/images/tutorimages/BG.png"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="8"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1" style="color:#BA1628">
            Forgot Your Password 
          </b-card-title>
          <b-card-text class="mb-2 bold_text">
            Please enter the email address you'd like your password reset information sent to.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
            >
              <b-form-group>
              <label label-for="forgot-password-email">Email<span class="redcolorstar">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="email "
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click.prevent="sendForgottePassLink()"
              >
                <span v-if="!isLogging">
                  Request Reset Link
                </span>
                <span v-else>
                  <b-spinner small/>
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}" class="bold-text">
              <feather-icon icon="ChevronLeftIcon--" /> Back to Log In
            </b-link>
          </p>
        </b-col>
        <div style="text-align:center;width:100%;position: absolute;bottom: 20px;right: 0px;">
          <!-- <a @click.prevent="$router.replace({name:'versionControl'}).catch(()=>{})">
            Version {{projectVersion}}
          </a> -->
        </div>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import { version } from '@/../package.json'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//FIREBASE 
import firebase from "firebase";
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from '@/utils/firebaseCollection.js';
export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      projectVersion:version,
      
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      isLogging:false,
      allUser:[]
    }
  },
  // watch: {
  //   $route: function () {
  //     this.init();          
  //   }
  // },
  created(){
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    sendForgottePassLink(){
      debugger // eslint-disable-line
      this.isLogging = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
           getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS,'email','==',this.userEmail,(res)=>{
              if(res && res.length > 0){
                let userData = res;
                // console.log('userData',userData)
                if(userData[0].userRole !== undefined && userData[0].userRole != 'admin'){
                  this.$axios.post(process.env.VUE_APP_EMAIL_SENT_API + '/api/v1/forgotPassword',{
                      userData:userData[0],
                  }).then((res) => {
                      if(res.data.status){
                        this.isLogging =false;
                        this.$refs.simpleRules.reset();
                        this.userEmail = '';
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Email sent successfully.',
                            icon: 'onedrivenew',
                            variant: 'success',
                            text:'Link is valid for 5 minutes.'
                          },
                        })
                      }else{
                        this.isLogging =false;
                        this.$refs.simpleRules.reset();
                        this.userEmail = '';
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: res.data.statusText,
                            icon: 'onedrivenew',
                            variant: 'danger',
                          },
                        })
                      }
                  }).catch(error=>{
                    this.isLogging=false
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `${error}`,
                        icon: 'onedrivenew',
                        variant: 'danger',
                      },
                    })
                  })
                }else{
                  this.isLogging=false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User record not found',
                      icon: 'onedrivenew',
                      variant: 'danger',
                    },
                  })
                }            
              }else{
                this.isLogging=false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Email not exist.',
                    icon: 'onedrivenew',
                    variant: 'danger',
                  },
                })
              }
          })
        }
        else
        {
          this.isLogging=false
        }
      })
    }
  },
}
</script>
<style >
a.bold-text:hover {
  color: #0096DB;
  text-decoration: underline;
  text-underline-position: from-font;
}
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
