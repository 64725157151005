<template>
  <div>
      <button @click="startMeet">Join Meeting</button>
      <button @click="endMeet">End Meeting</button>
      <video width="320" height="240" controls id="self-view-video">
        Your browser does not support the video tag.
      </video>
    <!-- <div class="iframe-container" style="overflow: hidden; padding-top: 56.25%; position: relative;">
          <iframe allow="microphone; camera" style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;" src="https://success.zoom.us/wc/join/{meeting-id}" frameborder="0"></iframe>
        </div> -->
  </div>
</template>

<script>
import ZoomVideo from '@zoom/videosdk';
const client = ZoomVideo.createClient()
import { KJUR } from 'jsrsasign';
client.init('en-US',`CDN`)
let stream;
export default {
  name: "HelloWorld",
  data() {
    return {
      userData:{}
    };
  },
  created(){
   this.userData = JSON.parse(localStorage.getItem('userData'));
   console.log(this.userData,'sfsd')
  },
  methods: {
    async startMeet() {
      // debugger // eslint-disable-line
      console.log('dasdasd', this.userData);
     
      const zoomConfig = {
        sdkKey: 'PUaeNX49RpohWnrSDc8XbgHvkFU1EjPNuSIr',
        sdkSecret: '6JcrUk6m5tvEmOgas8uQaPXn0oVPrxKHfeTT',
        topic: 'Test',
        password: 'abc@223133',
        userName: this.userData.fullName,
        sessionKey: 'vuejs',
        user_identity: 'Host',
        roleType: 1
      }
      const token = await this.generateSessionToken(zoomConfig);
      console.log('token => ', token)
      client.join(zoomConfig.topic, token, zoomConfig.userName, zoomConfig.password).then(() => {
        stream = client.getMediaStream();
        stream.startVideo({ videoElement: document.querySelector('#self-view-video') })
        // const chat = client.getChatClient();
        //   client.on('chat-on-message', (payload) => {
        //     console.log(payload)
        //     console.log(`Message: ${payload.message}, from ${payload.sender.name} to ${payload.receiver.name}`)
        //   })
        // chat.sendToAll('Hey everyone');
        // chat.getHistory()
      }).catch((error) => {
        console.log(error)
      })
    },
    endMeet(){
      client.leave()
    },
    generateSessionToken(obj) {
      console.log('obj => ', obj)
      let signature = '';
      try {
        const iat = Math.round(new Date().getTime() / 1000);
        const exp = iat + 60 * 60 * 2;
        // Header
        const oHeader = { alg: 'HS256', typ: 'JWT' };
        // Payload
        const oPayload = {
          app_key: obj.sdkKey,
          iat,
          exp,
          tpc: obj.topic,
          pwd: obj.passWord,
          user_identity: obj.userIdentity,
          session_key: obj.sessionKey,
          role_type: obj.roleType, // role=1, host, role=0 is attendee, only role=1 can start session when session not start
        };
        const sHeader = JSON.stringify(oHeader);
        const sPayload = JSON.stringify(oPayload);
        signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, obj.sdkSecret);
      } catch (e) {
        console.error(e);
      }
      return signature;
    }
  }
};
</script>