import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()

/******* QUERY FOR GET DATA USIN QUERY SNAPSHOT FROM FIRESTORE ROOT COLLECTION *******************/
export const getDataFromRootCollection = (collectionName, cb) => {
    db.collection(collectionName).onSnapshot((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
}

/******* QUERY FOR GET DATA QUERYT FROM FIRESTORE ROOT COLLECTION *******************/
export const simpleGetDataFromRootCollection = (collectionName, cb) => {
    db.collection(collectionName).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
}

/******* QUERY FOR GET DATA WITH ONSNAPSHOT FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION *******************/
export const getDataWithSpecificWhereCondition = ( collectionName, fieldName, condition, fieldValue, cb) => {
    db.collection(collectionName).where(fieldName, condition, fieldValue).onSnapshot((snapshot) => {        
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {              
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
            return;
        } else {
            cb(response);
            return;
        }
    });
}

  /******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION *******************/
export const simpleGetDataWithSpecificWhereCondition = ( collectionName, fieldName, condition, fieldValue, cb) => {  
    db.collection(collectionName).where(fieldName, condition, fieldValue).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {               
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
            return;
        } else {
            cb(response);
            return;
        }
    });
  }

  /******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION AND ORDER BY CREATEDAT *******************/
export const getDataWithSpecificWhereConditionAndOrderBy = ( collectionName, cb) => {
    db.collection(collectionName).orderBy('createdAt','desc').onSnapshot((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
  }

/******* QUERY FOR GET DATA ONSNAPSHOT FROM FIRESTORE ROOT COLLECTION *******************/
export const getDataFromRootCollectionWithDocID = (collectionName,id, cb) => {
    db.collection(collectionName).doc(id).onSnapshot((doc) => {
        let response = {};
        if (doc.exists) {          
            response = doc.data();
            cb(response);
        } else {           
            cb(response);
        }
    });
}

/******* QUERY FOR GET DATA FROM FIRESTORE COLLECTION *******************/
export const simpleGetDataFromSubCollectionWithDocID = (collectionName,id, cb) => {
    db.collection(collectionName).doc(id).get().then((doc) => {
        let response = {};
        if (doc.exists) {
            response = doc.data();
            cb(response);
        } else {
            cb(response);
        }
    });
}

//helee
/******* QUERY FOR GET DATA FROM FIRESTORE SUB COLLECTION *******************/
export const simpleGetDataFromSubCollection = (collectionName,id, subCollectionName, cb) => {
    db.collection(collectionName).doc(id).collection(subCollectionName).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
}

    /******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH TWO WHERE CONDITION *******************/
export const simpleGetDataWithSpecificTwoWhereCondition = (collectionName, fieldName, condition, fieldValue, fieldName1, condition1, fieldValue1, cb) => {
    db.collection(collectionName).where(fieldName, condition, fieldValue).where(fieldName1, condition1, fieldValue1).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({ id: doc.id, ...doc.data() })
            });
            cb(response);
            return;
        } else {
            cb(response);
            return;
        }
    });
}

    /******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH THREE WHERE CONDITION *******************/
export const simpleGetDataWithSpecificThreeWhereCondition = (
    collectionName,
    fieldName,
    condition,
    fieldValue,
    fieldName1,
    condition1,
    fieldValue1,
    fieldName2,
    condition2,
    fieldValue2,
    cb) => {
        db.collection(collectionName)
            .where(fieldName, condition, fieldValue)
            .where(fieldName1, condition1, fieldValue1)
            .where(fieldName2, condition2, fieldValue2).get().then((snapshot) => {
                let response = [];
                if (!snapshot.empty) {
                    snapshot.forEach((doc) => {
                        response.push({ id: doc.id, ...doc.data() })
                    });
                    cb(response);
                    return;
                } else {
                    cb(response);
                    return;
                }
            }
        );
    }

/******* QUERY FOR GET DATA FROM FIRESTORE COLLECTION VERSION 2*******************/
export const simpleGetDataFromSubCollectionWithDocIDV2 = (collectionName,id, cb) => {
    db.collection(collectionName).doc(id).get().then((doc) => {
        let response = {};
        if (doc.exists) {
            response = doc.data();
            // cb(response);
            cb({data:response,status:true});
        } else {
            cb({data:response,status:false});
        }
    });
}

/******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION VERSION (Jeel)*******************/
export const simpleGetDataWithSpecificWhereConditionV2 = ( collectionName, fieldName, condition, fieldValue, cb) => {  
    db.collection(collectionName).where(fieldName, condition, fieldValue).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {               
                response.push({docId: doc.id, ...doc.data()})
            });
            cb({data:response,status:true});
            return;
        } else {
            cb({data:response,status:false});
            return;
        }
    });
}
