<template>
  <div>
   <b-spinner
      variant="primary"
      v-if="showSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!showSpinner">
      <b-spinner
        variant="primary"
        v-if="showSpinner"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
        <p>Email Verification</p>
        <button v-on:click="resendLink" v-if="showResendButton">ResendEmail</button>
    </div>   
  </div>
   
</template>

<script>
import axios from "axios";
import {BSpinner}  from "bootstrap-vue";
// import {emailVerification} from './service.js'
export default {
name : "EmailVerification",
components: {
    BSpinner
},
data() {
    return {
        showSpinner : true,
        showResendButton: false,
   
    }
},
created(){
    console.log("id", this.$route.params.varifyId);
    console.log("id", this.$route.query.token);

    // axios.post(process.env.VUE_APP_EMAIL_SENT_API + "/api/v1/emailVerification", {
    axios.post(process.env.VUE_APP_EMAIL_SENT_API + "/api/v1/verifyEmail", {
        uid: this.$route.params.varifyId,
        token: this.$route.query.token,
    })
    .then((res) => {
        console.log("res", res);
        debugger // eslint-disable-line
      this.showSpinner = false;
      if (!res.status) {
        alert("Some thing went wrong");
      } else if (res.status) {
        if (res.data.statusText.showResendVerification === false) {
          this.showSpinner = false;
          window.location.replace(process.env.VUE_APP_MEETING_WEBDOMAINLINK);
          alert(`${res.data.statusText.message}`)
          this.showResendButton = false;
        } else if (res.data.statusText.showResendVerification === true) {
          this.message = res.data.statusText;
          this.showSpinner = false;
          alert(`${res.data.statusText.message}`)
          this.showResendButton = false;
        } else if (res.data.statusText.resendEmailVerification === true) {
          this.showSpinner = false;
          alert(`${res.data.statusText.message}`)
          this.showResendButton = true;
        }
      }else if(!res.status){
        console.log("Some thing went wronge.")
      }
    }).catch((err) => {
        console.log(err);
    });
 
    
},
methods: {
  resendLink() {
    debugger // eslint-disable-line
    this.showSpinner = true;
    axios.post(process.env.VUE_APP_EMAIL_SENT_API + "/api/v1/resendEmailVerification", {
      uid: this.$route.params.varifyId,
      domainLink: process.env.VUE_APP_MEETING_WEBDOMAINLINK,
    }).then(response => {
      console.log('response',response);
      this.$root.$emit('showToastMessage',`${response.data.statusText}`,'success');
      window.location.replace(process.env.VUE_APP_MEETING_WEBDOMAINLINK);
      this.showSpinner = false;
    }).catch(error => {
      console.log(error,'error');
    })
  }
}
}
</script>

<style>

</style>